import React, {useEffect, useState} from "react";
import {Box, Button, Grid, Paper, Rating, TextField, Typography} from "@mui/material";
import {
	CustomerEvaluationList,
	CustomerEvaluationResult,
	ItemList,
	PubCustomerSupportRestControllerApiFactory,
} from "arteco-api-client-ts";
import {useApiManager} from "../../components/utils/api";
import {H1, PARAGRAPH, Typographies} from "../../components/utils/typography_consts";
import {HOME_ARTECO} from "../../components/utils/urls";
import {AppLayout} from "../../components/layout/layout";
import {useSearchParams} from "react-router-dom";
import {SupportHeader} from "./header";


export const SupportPage = () => {
	const apiManager = useApiManager();
	const ctrl = apiManager.factory(PubCustomerSupportRestControllerApiFactory);
	const companyId = apiManager.companyId;

	const [searchParams, setSearchParams] = useSearchParams();
	const linkId = parseInt(searchParams.get("rid") || "0") || 0;

	const [isSentForm, setIsSentForm] = useState(false);
	const [hasEmptyQuestions, setHasEmptyQuestions] = useState(true);
	const [evaluation, setEvaluation] = useState<CustomerEvaluationList>();
	const [customerResponse, setCustomerResponse] = useState<Map<number, Map<number, number | null>>>(new Map());
	const [customerComments, setCustomerComments] = useState<Map<number, string>>(new Map());


	useEffect(() => {
		if (linkId) {
			apiManager
			.execute(ctrl.getEmployeeSupport(companyId, linkId))
			.then(item => setEvaluation(item));
		}
	}, [linkId]);

	useEffect(() => {
		if (evaluation) {
			let ok = true;
			const people = evaluation?.people || [];
			for (let pi of people) {
				const pid = parseInt(pi.value as string);
				const questions = evaluation?.questions || [];
				for (let qi of questions) {
					const qid = parseInt(qi.value as string);
					const personQuestions = customerResponse.get(pid)
					if (!personQuestions || personQuestions.get(qid) == null) {
						ok = false;
						break;
					}
				}
				if (!ok) {
					break;
				}
			}
			setHasEmptyQuestions(!ok);
		}
	}, [customerResponse]);

	const updateCustomerResponse = (outerKey: number, innerKey: number, value: number | null) => {
		const newMap = new Map(customerResponse);
		const innerMap = newMap.get(outerKey) ? new Map(newMap.get(outerKey)) : new Map();
		innerMap.set(innerKey, value);
		newMap.set(outerKey, innerMap);
		setCustomerResponse(newMap);
	};


	return (
		<AppLayout permitAnon={true} maxWidth="md">

			<SupportHeader title={"Ayúdanos a mejorar"}
						   subTitle={"queremos ser tu partner digital"}/>

			<Box>
				{isSentForm && (
					<Paper>
						<Box p={3}>
							<Typography my={4}
										variant={Typographies.h4}
										component={H1}>
								Tu respuesta ha sido enviada con éxito
							</Typography>
							<Typography py={3} component={PARAGRAPH}>
								¡Gracias por hacernos llegar el feedback de los servicios que ofrecemos!
								Usamos esta información para hacer un seguimiento del equipo técnico y
								detectar áreas de mejora con el fin de ofrecer la mejor calidad posible.
							</Typography>

							<Typography py={3} component={PARAGRAPH}>
								Puedes continuar revisando más contenido en la página
								&nbsp;<a href={HOME_ARTECO}>web de Arteco</a>.
							</Typography>
						</Box>
					</Paper>
				)}

				{!isSentForm && !evaluation && (
					<Paper sx={{p: 4, mt: 1}}>
						<Typography sx={{my: 1}} variant={"h5"}>
							El enlace proporcionado ya no es válido
						</Typography>
						<Typography sx={{my: 1}} variant={"body1"}>
							Si se trata de un error puede ponerse en contacto con
							rrhh@arteco-consulting.com
						</Typography>
						<Typography py={3} component={PARAGRAPH}>
							Puedes continuar revisando más contenido en la página
							&nbsp;<a href={HOME_ARTECO}>web de Arteco</a>.
						</Typography>
					</Paper>
				)}

				{!isSentForm && evaluation && (

					<Paper sx={{p: 4, mt: 1}}>
						<Typography sx={{my: 1}} variant={"h5"}>
							¿Estás satisfecho con el servicio de nuestros técnicos?
						</Typography>
						<Typography sx={{my: 1}} variant={"body1"}>
							Evalúa a nuestros técnicos rápidamente con este sencillo cuestionario.
							Tu opinión nos ayudará a identificar áreas de mejora para ofrecerte
							un servicio excepcional.
						</Typography>
						<Typography sx={{my: 1}} variant={"body1"}>
							Las valoraciones se <b>registran de forma anónima como puntos de una
							serie temporal</b> y se analizan en conjunto para estudiar tendencias
							generales.
						</Typography>

						{evaluation && (
							<>
								<Typography sx={{my: 1, mb: 4}} variant={"body1"}>
									<b>Marca una opción</b> de {evaluation.options?.length} respuestas
									posibles
									para las {evaluation.questions?.length} preguntas que hacemos sobre cada uno
									nuestros técnicos con dedicación en el servicio.
									En tu caso, necesitamos tu colaboración para las siguientes personas:
									&nbsp;
								</Typography>


								{evaluation.people?.map((p, pi) => (
									<Box key={"pers-" + pi} sx={{mb: 4}}>
										<Typography sx={{my: 1}} variant={"h6"}>
											{p.label}
										</Typography>

										<table className={"table"}>
											<tbody>
											{evaluation.questions?.map((q, qi) => (
												<tr>
													<td>{q.label}</td>
													<td>
														<Rating
															max={evaluation.options?.length}
															value={customerResponse.get(parseInt(p.value as string))?.get(parseInt(q.value as string)) || 0}
															onChange={(event, newValue) => {
																const pid = parseInt(p.value as string);
																const qid = parseInt(q.value as string);
																updateCustomerResponse(pid, qid, newValue);
															}}
														/>
													</td>
												</tr>
											))}
											</tbody>
										</table>
										<Box sx={{p: 1}}>
											<TextField sx={{}}
													   label={"Comentarios"}
													   fullWidth
													   variant={"standard"}
													   value={customerComments.get(parseInt(p.value as string)) || ''}
													   onChange={e => {
														   const newComments = new Map<number, string>(customerComments);
														   newComments.set(parseInt(p.value as string), e.target.value);
														   setCustomerComments(newComments)
													   }}/>
										</Box>
									</Box>
								))}

								<Box>
									<Grid container spacing={2}>
										<Grid item>
											<Button variant={"contained"} disabled={hasEmptyQuestions} color={"primary"}
													onClick={() => {
														const newComments = {} as any;
														for (const [key, value] of customerComments) {
															newComments[key] = value;
														}

														const newResponse = {} as any;
														for (const [key, value] of customerResponse) {
															const questionarie = {} as any;
															for (const [qkey, respPos] of value) {
																if (respPos && evaluation?.options) {
																	const qresp = evaluation?.options[respPos-1] as ItemList;
																	questionarie[qkey] = parseInt(qresp.value as string);
																}
															}
															newResponse[key] = questionarie;
														}

														const result: CustomerEvaluationResult = {
															evaluations: newResponse,
															comments: newComments
														};
														apiManager.execute(ctrl.saveEmployeeSupport(companyId, linkId, result))
														.then(r => {
															setIsSentForm(true);
														})
													}}>
												Enviar
											</Button>
										</Grid>
										<Grid item>
											{hasEmptyQuestions && (
												<Box sx={{pt: 1}}>Por favor, cumplimente todos los puntos</Box>
											)}
										</Grid>
									</Grid>
								</Box>

							</>
						)}
					</Paper>
				)}
			</Box>
		</AppLayout>
	);
};