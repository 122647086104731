import {Contact, ContactRestControllerApiFactory} from "arteco-api-client-ts";
import AppList, {AppListConfig} from "../../../../components/layout/list";
import {COLOR_DISMISSED} from "../../../../components/layout/colors";
import {Grid} from "@mui/material";
import {useApiManager} from "../../../../components/utils/api";
import React, {useEffect, useState} from "react";
import {fileLocalSave} from "../../../../components/form/form_inputs/form_file";
import {useSearchParams} from "react-router-dom";


export const useContact = (contactId: number | undefined): [(Contact | undefined), React.Dispatch<React.SetStateAction<Contact | undefined>>] => {
	const apiManager = useApiManager();
	const [contact, setContact] = useState<Contact | undefined>();
	const ctrl = apiManager.factory(ContactRestControllerApiFactory);

	useEffect(() => {
		if (contactId) {
			apiManager
			.execute(ctrl.getContact(contactId))
			.then(c => setContact(c));

		}
	}, [contactId]);
	return [contact, setContact];
};


export const ContactListPage = () => {
	const apiManager = useApiManager();
	const ctrl = apiManager.factory(ContactRestControllerApiFactory);
	const companyId = apiManager.companyId;
	const [searchParams, setSearchParams] = useSearchParams();
	const orgId = searchParams.get("orgId");
	const organizationId = orgId ? parseInt(orgId || "0") : undefined;

	const [trigger, setTrigger] = useState<number>(0);
	const [withoutEmail, setWithoutEmail] = useState<boolean | undefined>(undefined);


	const listConfig: AppListConfig<Contact> = {
		title: "Listado de contactos "+(orgId ? "de " + orgId : ""),
		onClick: "/app/admin/marketing/contact",
		decorator: (bean: Contact) => (
			<Grid container style={{color: bean.dismissed ? COLOR_DISMISSED : ""}}>
				<Grid item sm>
					{bean.surnamesAndName}
				</Grid>
				<Grid item sm>
					{bean.organization?.name}
				</Grid>
				<Grid item sm>
					{bean.position}
				</Grid>
				<Grid item sm>
					{bean.email}
				</Grid>
				<Grid item sm={3}>
					{bean.noDisturb ? "!!!" : ""}
				</Grid>
			</Grid>
		),
		supplier: async (currentPage, size, term, showDismissed) => {
			const companyId = apiManager.companyId;
			return ctrl.pageContact(companyId, currentPage, size, undefined,
				undefined, organizationId, withoutEmail, term, showDismissed);
		},
		actions: [
			{
				action: () => {
					apiManager.executeRaw(ctrl.exportContact(companyId)).then((resp) => {
						fileLocalSave(resp);
					});
				},
				label: "Exportar"
			},
			{
				color: !withoutEmail ? "primary" : "secondary",
				action: () => {
					setWithoutEmail(!withoutEmail);
					setTrigger(trigger + 1);
				},
				label: "Sin email",
			},
		]
	};

	return (
		<>
			<AppList config={listConfig} trigger={trigger}/>
		</>
	);
};